import { useEffect, useState } from "react";
import logo from "../../Assets/logo.png";
import { useLocation } from "react-router-dom";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  ContactMail,
  DataUsage,
  Home,
  Info,
  PrivacyTip,
} from "@mui/icons-material";

export default function Header(props) {
  const [isSticky, setSticky] = useState(false);
  const [active, setActive] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const jwt = require("jsonwebtoken");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <img
        onClick={() => {
          window.location.href = "/";
        }}
        src={logo}
        style={{
          maxHeight: "104px",
          objectFit: "contain",
          margin: " 2em auto 1em auto",
          display: "block",
        }}
        alt=""
      />
      <Typography
        variant="h5"
        noWrap
        component="a"
        href="#app-bar-with-responsive-menu"
        sx={{
          fontWeight: 700,
          letterSpacing: ".1rem",
          color: "inherit",
          display: "block",
          textDecoration: "none",
          textAlign: "center",
          color: "#525ceb",
          padding: "1rem",
        }}
        onClick={() => {
          window.location.href = "/";
        }}
        gutterBottom
      >
        TSwapMate
      </Typography>

      <Divider />
      <List>
        <ListItemButton
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            window.location.href = "/about";
          }}
        >
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText primary={"About"} />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            window.location.href = "/contact";
          }}
        >
          <ListItemIcon>
            <ContactMail />
          </ListItemIcon>
          <ListItemText primary={"Contact"} />
        </ListItemButton>
        <Divider />
        <ListItemButton
          onClick={() => {
            window.location.href = "/privacy";
          }}
        >
          <ListItemIcon>
            <PrivacyTip />
          </ListItemIcon>
          <ListItemText primary={"Privacy Policy"} />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            window.location.href = "/terms";
          }}
        >
          <ListItemIcon>
            <DataUsage />
          </ListItemIcon>
          <ListItemText primary={"Terms and Conditions"} />
        </ListItemButton>
      </List>
      <Divider />
      <Box p={5}>
        <ColorButton
          sx={{ my: 2, color: "white", margin: "auto", display: "block" }}
          onClick={() => {
            if (isAuthenticated) logout();
            else {
              window.location.href = "/login";
            }
          }}
          variant="contained"
        >
          {isAuthenticated ? "Logout" : "Login/Register"}
        </ColorButton>
      </Box>
    </Box>
  );

  useEffect(() => {
    const token = localStorage.getItem("tswap-fmgdfhgfhtkn");
    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [active, jwt]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const Item = ({ txt, link }) => {
    return (
      <MenuItem
        onClick={() => {
          window.location.href = link;
        }}
      >
        <Typography sx={{ fontWeight: 400 }} variant="h6" textAlign="center">
          {txt}
        </Typography>
      </MenuItem>
    );
  };

  function logout() {
    fetch("/api/user/logout", {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.json())
      .then(() => {
        setIsAuthenticated(false);
        localStorage.removeItem("tswap-fmgdfhgfhtkn");
        window.location.href = "/";
      })
      .catch(() => {
        localStorage.removeItem("tswap-fmgdfhgfhtkn");
        window.location.href = "/";
      });
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        background: isSticky ? "rgba(255, 255, 255, 0.8)" : "transparent",
        backdropFilter: isSticky ? "blur(10px)" : "none",
        boxShadow: isSticky ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none",
        transition: "background 0.3s, box-shadow 0.3s",
        color: "#525ceb",
      }}
    >
      <Container disableGutters>
        <Toolbar>
          <img
            onClick={() => {
              window.location.href = "/";
            }}
            src={logo}
            style={{ maxHeight: "44px", objectFit: "contain" }}
            alt=""
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex" },
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
              flexGrow: { xs: 1, md: 0.3 },
              marginLeft: 1,
            }}
            onClick={() => {
              window.location.href = "/";
            }}
          >
            TSwapMate
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Item txt="Home" link="/" />
            <Item txt="About" link="/about" />{" "}
            <Item txt="Contact" link="/contact" />
          </Box>
          <ColorButton
            sx={{ my: 2, color: "white", display: { xs: "none", md: "flex" } }}
            onClick={() => {
              if (isAuthenticated) logout();
              else {
                window.location.href = "/login";
              }
            }}
            variant="contained"
          >
            {isAuthenticated ? "Logout" : "Login/Register"}
          </ColorButton>
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={toggleDrawer(true)}
            color="inherit"
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#525ceb"),
  backgroundColor: "#525ceb",
  "&:hover": {
    backgroundColor: "#FA541C",
  },
}));
