import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import PaymentDetailsDialog from "./PaymentDetailsDialog"; // Ensure this import path matches your structure

export default function Billing() {
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchPayments();
  }, [page, rowsPerPage]);

  const fetchPayments = async () => {
    try {
      const response = await fetch(
        `/api/payments/paginated/${page + 1}/${rowsPerPage}`
      );
      if (response.ok) {
        const data = await response.json();
        setPayments(data.payments);
        setTotalCount(data.totalCount);
      }
    } catch (error) {
      console.error("An error occurred while fetching payments", error);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "warning";
      case "Confirmed":
        return "success";
      case "Failed":
        return "error";
      default:
        return "error";
    }
  };

  const handleRowClick = (payment) => {
    setSelectedPayment(payment);
    setDetailsOpen(true);
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
    setSelectedPayment(null);
  };

  const renderTable = () => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>SN</TableCell>
          <TableCell>Mpesa Code</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {payments.map((payment, index) => (
          <TableRow
            key={payment.ID}
            onClick={() => handleRowClick(payment)}
            style={{ cursor: "pointer" }}
          >
            <TableCell>
              <Chip label={page * rowsPerPage + index + 1} />
            </TableCell>
            <TableCell sx={{ maxWidth: "250px" }}>
              {payment.MpesaCode}
            </TableCell>
            <TableCell>{payment.Amount}</TableCell>
            <TableCell>
              {dayjs(payment.PaymentDate).format("DD/MM/YYYY")}
            </TableCell>
            <TableCell>
              <Chip
                label={payment.Status}
                color={getStatusColor(payment.Status)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const renderCards = () => (
    <Box>
      {payments.map((payment, index) => (
        <Card
          key={payment.ID}
          sx={{ mb: 2, p: 2, cursor: "pointer" }}
          onClick={() => handleRowClick(payment)}
        >
          <Stack spacing={1}>
            <Box display="flex" justifyContent="space-between">
              <Chip label={page * rowsPerPage + index + 1} />
              <Typography variant="body2">
                {dayjs(payment.PaymentDate).format("DD/MM/YYYY")}
              </Typography>
            </Box>
            <Typography
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4,
                overflow: "hidden",
              }}
              variant="h6"
            >
              {payment.MpesaCode}
            </Typography>
            <Typography variant="body2">Amount: {payment.Amount}</Typography>
            <Chip
              label={payment.Status}
              color={getStatusColor(payment.Status)}
              sx={{ alignSelf: "flex-start" }}
            />
          </Stack>
        </Card>
      ))}
    </Box>
  );

  return (
    <Box marginTop={8}>
      <Stack spacing={3}>
        {isMobile ? renderCards() : renderTable()}
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Stack>
      {selectedPayment && (
        <PaymentDetailsDialog
          open={detailsOpen}
          onClose={handleDetailsClose}
          payment={selectedPayment}
        />
      )}
    </Box>
  );
}
