import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  Stack,
  Alert,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { z as zod } from "zod";

const loginSchema = zod.object({
  email: zod.string().min(1, { message: "Email is required" }).email(),
  password: zod.string().min(1, { message: "Password is required" }),
});

const defaultLoginValues = { email: "", password: "" };

export default function LoginForm({ showForgotPassword }) {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: defaultLoginValues,
  });
  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState("");

  const onSubmit = (data) => {
    setIsError("");
    setIsPending(true);

    fetch("/api/user/login", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((error) => {
            throw new Error(error.message || "Failed!");
          });
        }
      })
      .then((data) => {
        setIsPending(false);

        if (data.token) {
          setIsError(data.message || "Login successful!");
          setTimeout(() => {
            navigate("/dashboard/home");
          }, 2000);
        } else {
          setIsError(data.message || "Login failed!");
        }
      })
      .catch((err) => {
        setIsPending(false);
        setIsError(err.message);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <FormControl error={Boolean(errors.email)}>
              <InputLabel>Email address</InputLabel>
              <OutlinedInput {...field} label="Email address" type="email" />
              {errors.email ? (
                <FormHelperText>{errors.email.message}</FormHelperText>
              ) : null}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <FormControl error={Boolean(errors.password)}>
              <InputLabel>Password</InputLabel>
              <OutlinedInput {...field} label="Password" type="password" />
              {errors.password ? (
                <FormHelperText>{errors.password.message}</FormHelperText>
              ) : null}
            </FormControl>
          )}
        />
        <div>
          <Link
            onClick={() => {
              showForgotPassword(true);
            }}
            variant="subtitle2"
            sx={{
              textAlign: "center",
              color: "#0693e3",
              cursor: "pointer",
            }}
          >
            Forgot password?
          </Link>
        </div>
        {isError && (
          <Alert
            color={isError?.includes("successful") ? "success" : "warning"}
          >
            {isError}
          </Alert>
        )}
        <Button
          disabled={isPending}
          type="submit"
          variant="contained"
          size="large"
        >
          {isPending ? <CircularProgress size={24} /> : "Sign in"}
        </Button>
      </Stack>
    </form>
  );
}
