import React, { useState } from "react";
import { Box, Button, Typography, Link, Stack } from "@mui/material";
import lg_img from "../Assets/auth-widgets.png";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import ForgotPassword from "./ForgotPassword";
import { ArrowBackIos } from "@mui/icons-material";
import Header from "../Components/Utils/header";

export default function LoginPage() {
  const [isRegister, setIsRegister] = useState(false);
  const [showing, setShowing] = useState(false);

  return (
    <Box
      sx={{
        display: { xs: "flex", lg: "grid" },
        flexDirection: "column",
        gridTemplateColumns: "1fr 1fr",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{ display: { xs: "block", sm: "block", md: "none", xl: "none" } }}
      >
        <Header />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flex: "auto",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            p: 3,
            display: "block",
            width: "100%",
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "85vw", sm: "85vw", md: "450px" },
              width: "100%",
              margin: "auto",
            }}
          >
            <Stack spacing={4}>
              <Button
                onClick={() => {
                  window.location.href = "/";
                }}
                startIcon={<ArrowBackIos />}
                variant="outlined"
                color="secondary"
                sx={{
                  width: "120px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    xl: "flex",
                  },
                }}
              >
                Back
              </Button>
              <Stack spacing={1}>
                <Typography
                  sx={{ textAlign: "center", color: "#0693e3" }}
                  variant="h4"
                >
                  {isRegister ? "Register" : "Sign in"}
                </Typography>
              </Stack>
              {isRegister ? (
                <RegisterForm setIsRegister={setIsRegister} />
              ) : (
                <LoginForm showForgotPassword={setShowing} />
              )}
              <Link
                onClick={() => setIsRegister(!isRegister)}
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                  color: "#0693e3",
                }}
              >
                {isRegister
                  ? "Already have an account? Sign in"
                  : "Don't have an account? Register"}
              </Link>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          background:
            "radial-gradient(50% 50% at 50% 50%,#8ed1fc  0%, #0693e3 100%)",
          color: "var(--mui-palette-common-white)",
          display: { xs: "none", lg: "grid" },
          gridTemplateRows: "auto 1fr",
          justifyContent: "center",
          p: 3,
          minHeight: "100%",
        }}
      >
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography
              color="inherit"
              sx={{
                fontSize: "32px",
                color: "orange",
                lineHeight: "32px",
                textAlign: "center",
              }}
              variant="h1"
            >
              Welcome to <br />
              <Box component="span" sx={{ color: "white" }}>
                TSwapMate
              </Box>
            </Typography>
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              alt="Widgets"
              src={lg_img}
              sx={{ height: "auto", width: "100%", maxWidth: "500px" }}
            />
          </Box>
        </Stack>
        <ForgotPassword open={showing} showForgotPassword={setShowing} />
      </Box>
    </Box>
  );
}
