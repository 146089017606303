import AboutHero from "../Components/About/ahero";
import HowItWorks from "../Components/About/how_it_works";
import WhyUS from "../Components/About/why_us";
import Footer from "../Components/Utils/footer";
import Header from "../Components/Utils/header";
import "../Styles/about.scss";

export default function About(props) {
  return (
    <div className="about">
      <Header />
      <AboutHero />
      <HowItWorks />
      <WhyUS />
      <Footer />
    </div>
  );
}
