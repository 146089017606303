import React, { useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Button,
} from "@mui/material";
import { MoneyOff, CheckRounded, Close } from "@mui/icons-material";
import EnquiryDialog from "../../Pages/EnquiryDialog";

export default function Pricing(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [title, setTitle] = useState("");
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "2rem", marginBottom: "2rem" }}
      gutterBottom
    >
      <Typography
        sx={{ textAlign: "center" }}
        variant="h4"
        style={{ marginBottom: "2rem" }}
        gutterBottom
      >
        The Right Plan For You
      </Typography>
      <Typography
        sx={{ textAlign: "center" }}
        variant="body1"
        style={{ marginBottom: "1rem" }}
        gutterBottom
      >
        Choose the perfect plan for your needs. Always flexible to grow
      </Typography>
      <Grid container spacing={3} alignItems="stretch">
        {/* Exact Matches */}
        <Grid item xs={12} md={3} gutterBottom>
          <Card
            sx={{
              borderRadius: 6,
              boxShadow: "4px 4px 10px rgba(0,0,0,0.1)",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  height: "44px",
                  color: "#fa541c",
                }}
                display="flex"
                justifyContent="center"
                mb={2}
              >
                <MoneyOff fontSize="large" />
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#252C36",
                }}
                variant="h6"
                gutterBottom
                paragraph
              >
                Alternative Matches
              </Typography>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Email notifications
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Automatic Matching
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Alternative Suggestions
                </Typography>
              </Grid>

              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Alternative County
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Alternative Subcounty
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Alternative Ward
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Alternative school
                </Typography>
              </Grid>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#525ceb",
                }}
                variant="h6"
              >
                KSh 1,000
              </Typography>
              <Button
                variant="outlined"
                sx={{ margin: "1.5em auto 0 auto", display: "block" }}
                onClick={() => {
                  handleOpenDialog();
                  setTitle("Exact Matches");
                }}
              >
                Enquire
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Ward to Ward */}
        <Grid item xs={12} md={3} gutterBottom>
          <Card
            sx={{
              borderRadius: 6,
              boxShadow: "4px 4px 10px rgba(0,0,0,0.1)",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  height: "44px",
                  color: "#fa541c",
                }}
                display="flex"
                justifyContent="center"
                mb={2}
              >
                <MoneyOff fontSize="large" />
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#252C36",
                }}
                variant="h6"
                gutterBottom
                paragraph
              >
                Ward to Ward
              </Typography>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Email notifications
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Automatic Matching
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Alternative Suggestions
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Exact County
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Exact Subcounty
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Exact Ward
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <Close sx={{ color: "#fa541c", marginRight: 1 }} item xs={1} />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Any school
                </Typography>
              </Grid>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#525ceb",
                }}
                variant="h6"
              >
                KSh 2,500
              </Typography>
              <Button
                variant="outlined"
                sx={{ margin: "1.5em auto 0 auto", display: "block" }}
                onClick={() => {
                  handleOpenDialog();
                  setTitle("Ward to Ward");
                }}
              >
                Enquire
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Subcounty to Subcounty */}
        <Grid item xs={12} md={3} gutterBottom>
          <Card
            sx={{
              borderRadius: 6,
              boxShadow: "4px 4px 10px rgba(0,0,0,0.1)",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  height: "44px",
                  color: "#fa541c",
                }}
                display="flex"
                justifyContent="center"
                mb={2}
              >
                <MoneyOff fontSize="large" />
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#252C36",
                }}
                variant="h6"
                gutterBottom
                paragraph
              >
                Subcounty to Subcounty
              </Typography>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Email notifications
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Automatic Matching
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Alternative Suggestions
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Exact County
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Exact Subcounty
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <Close sx={{ color: "#fa541c", marginRight: 1 }} item xs={1} />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Any Ward
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <Close sx={{ color: "#fa541c", marginRight: 1 }} item xs={1} />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Any School
                </Typography>
              </Grid>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#525ceb",
                }}
                variant="h6"
              >
                KSh 2,000
              </Typography>
              <Button
                variant="outlined"
                sx={{ margin: "1.5em auto 0 auto", display: "block" }}
                onClick={() => {
                  handleOpenDialog();
                  setTitle("Subcounty to Subcounty");
                }}
              >
                Enquire
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* County to County */}
        <Grid item xs={12} md={3} gutterBottom>
          <Card
            sx={{
              borderRadius: 6,
              boxShadow: "4px 4px 10px rgba(0,0,0,0.1)",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  height: "44px",
                  color: "#fa541c",
                }}
                display="flex"
                justifyContent="center"
                mb={2}
              >
                <MoneyOff fontSize="large" />
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#252C36",
                }}
                variant="h6"
                gutterBottom
                paragraph
              >
                County to County
              </Typography>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Email notifications
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Automatic Matching
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Alternative Suggestions
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <CheckRounded
                  sx={{ color: "green", marginRight: 1 }}
                  item
                  xs={1}
                />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Exact County
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <Close sx={{ color: "#fa541c", marginRight: 1 }} item xs={1} />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Any Subcounty
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <Close sx={{ color: "#fa541c", marginRight: 1 }} item xs={1} />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Any Ward
                </Typography>
              </Grid>
              <Grid container spacing={1} gutterBottom>
                <Close sx={{ color: "#fa541c", marginRight: 1 }} item xs={1} />
                <Typography
                  item
                  variant="body1"
                  style={{ fontSize: "small" }}
                  paragraph
                >
                  Any school
                </Typography>
              </Grid>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#525ceb",
                }}
                variant="h6"
              >
                KSh 1,500
              </Typography>
              <Button
                variant="outlined"
                sx={{ margin: "1.5em auto 0 auto", display: "block" }}
                onClick={() => {
                  handleOpenDialog();
                  setTitle("County to County");
                }}
              >
                Enquire
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <EnquiryDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={title + " Pricing"}
      />
    </Container>
  );
}
