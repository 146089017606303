import React from "react";
import { Container, Typography, Button, Box, Paper } from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { AllInclusive, AllOut, AltRoute } from "@mui/icons-material";

export default function WhyUS(props) {
  return (
    <Box sx={{ py: 8, backgroundColor: "#f5f5f5" }}>
      <Container>
        <Grid container xs={12} spacing={10} className="left">
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ fontWeight: 900, color: "#252C36" }}
              variant="h2"
              component="h1"
              gutterBottom
            >
              Core <span style={{ color: "#fa541c" }}>Values</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ fontWeight: 400, fontSize: "large", color: "#252C36" }}
              variant="body1"
              paragraph
              gutterBottom
            >
              At TSwapMate, we are committed to integrity and trust, ensuring
              transparency in all our interactions. Our user-centric approach
              prioritizes the needs and experiences of our users, while our
              dedication to innovation and excellence drives us to continuously
              improve our platform.
            </Typography>
          </Grid>
        </Grid>
        <Grid my={10} container spacing={4} alignItems="center">
          <Grid item xs={12} md={3}>
            <AccountTreeIcon
              sx={{ fontSize: "44px", color: "#fa541c" }}
              gutterBottom
            />
            <Typography variant="h6" sx={{ color: "#252C36" }} gutterBottom>
              Customer Satisfaction
            </Typography>
            <Typography variant="body1">
              Our users have the best experience possible is our
              top priority.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <AltRoute
              sx={{ fontSize: "44px", color: "#fa541c" }}
              gutterBottom
            />
            <Typography variant="h6" sx={{ color: "#252C36" }} gutterBottom>
              Transparency
            </Typography>
            <Typography variant="body1">
              We believe in open and honest communication with our users.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <AllOut
              sx={{ fontSize: "44px", color: "#fa541c" }}
              gutterBottom
            />
            <Typography variant="h6" sx={{ color: "#252C36" }} gutterBottom>
              Reputation
            </Typography>
            <Typography variant="body1">
              Our commitment to excellence has built a strong,
              positive reputation.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <AllInclusive
              sx={{ fontSize: "44px", color: "#fa541c" }}
              gutterBottom
            />
            <Typography variant="h6" sx={{ color: "#252C36" }} gutterBottom>
              Cooperation
            </Typography>
            <Typography variant="body1">
              We value collaboration, both within our organization
              and with our users.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
