import React from "react";
import { Container, Box, Grid, Typography, Paper } from "@mui/material";
import { styled } from "@mui/system";
import bg from "../../Assets/school.jpg"; // Make sure the path is correct

const Overlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay with opacity
  zIndex: 1,
}));

const BackgroundContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  padding: "4rem 0",
  height: { xs: "90vh", md: "400px" }, // Adjust height as needed
  backgroundImage: `url(${bg})`, // Correctly set the background image
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
}));

const Item = ({ title, value }) => (
  <Paper
    sx={{
      padding: 2,
      textAlign: "center",
      backgroundColor: "rgba(255, 255, 255, 0.2)", // Semi-transparent background
      backdropFilter: "blur(10px)", // Frosted glass effect
      borderRadius: 2,
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      color: "white",
    }}
  >
    <Typography variant="h6" sx={{ color: "#fa541c" }} gutterBottom>
      {title}
    </Typography>
    <Typography variant="h2">{value}</Typography>
  </Paper>
);

const Bar = ({ data }) => {
  return (
    <BackgroundContainer>
      <Overlay />
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          padding: 3,
          width: "100%",
        }}
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Item title="Completed Swaps" value={data ? data.complete : 0} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Item title="Active Swap Requests" value={data ? data.active : 0} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Item title="Total Teachers" value={data ? data.teachers : 0} />
          </Grid>
        </Grid>
      </Box>
    </BackgroundContainer>
  );
};

export default Bar;
