import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  Stack,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Divider,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { z as zod } from "zod";
import LocationSelector from "../Components/Utils/LocationSelector";

// Define the available options for Type and Subjects
const typeOptions = ["Secondary", "JSS", "Primary"];
const subjectOptions = [
  "All",
  "English",
  "Literature",
  "Mathematics",
  "Kiswahili",
  "Physics",
  "Chemistry",
  "Biology",
  "History",
  "Geography",
  "CRE",
  "IRE",
  "HRE",
  "Business Studies",
  "French",
  "German",
  "Arabic",
  "Kenya Sign Language",
  "Music",
  "Home Science",
  "Art & Design",
  "Agriculture",
  "Metal Work",
  "Wood Work",
  "Building Construction",
  "Electricity",
  "Power Mechanic",
  "Drawing & Design",
  "Aviation Technology",
  "Computer Studies",
];

// Define the validation schema with Subjects as a string
const stepSchemas = [
  zod.object({
    Email: zod.string().min(1, { message: "Email is required" }).email(),
    Password: zod.string().min(1, { message: "Password is required" }),
  }),
  zod.object({
    Name: zod.string().min(1, { message: "Name is required" }),
    Phone: zod.string().min(1, { message: "Phone number is required" }),
  }),
  zod.object({
    CurrentSchool: zod
      .string()
      .min(1, { message: "Current School is required" }),
    Type: zod.string().min(1, { message: "Type is required" }),
    Subjects: zod
      .string()
      .min(1, { message: "At least one subject is required" }),
  }),
  zod.object({
    CurrentCounty: zod.string().min(1, { message: "County is required" }),
    CurrentSubCounty: zod.string().min(1, { message: "SubCounty is required" }),
    CurrentWard: zod.string().min(1, { message: "Ward is required" }),
  }),
];

const defaultValues = {
  Email: "",
  Password: "",
  Name: "",
  Phone: "",
  Type: "",
  Subjects: "",
  CurrentCounty: "",
  CurrentSubCounty: "",
  CurrentWard: "",
  CurrentSchool: "",
};

const steps = ["Login", "Profile", "School", "Location"];

export default function RegisterForm(props) {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState(defaultValues);
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(stepSchemas[currentStep]),
    defaultValues: formData,
  });
  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState("");

  const handleNextStep = async (data) => {
    const isValid = await trigger();
    if (isValid) {
      handleSaveStepData(data);
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePreviousStep = () => setCurrentStep((prev) => prev - 1);

  const handleSaveStepData = (data) => {
    setFormData((prev) => ({ ...prev, ...data }));
  };

  const onSubmit = (data) => {
    handleSaveStepData(data);
    setIsError("");
    setIsPending(true);

    fetch("/api/user/register", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ ...formData, ...data }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((error) => {
            throw new Error(error.message || "Failed!");
          });
        }
      })
      .then((data) => {
        setIsPending(false);
        setIsError(data.message || "Registration successful");
        setTimeout(() => {
          props.setIsRegister(false);
        }, 1000);
      })
      .catch((err) => {
        setIsPending(false);
        setIsError(err.message);
      });
  };

  return (
    <Box>
      <Stepper
        sx={{
          flexWrap: "wrap",
          justifyContent: { xs: "center", sm: "flex-start" },
          gap: { xs: 1, sm: 2 },
        }}
        activeStep={currentStep}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={currentStep > index}>
            <StepLabel sx={{ fontSize: "12px" }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Divider sx={{ my: 2 }} />
      <form
        onSubmit={handleSubmit((data) => {
          if (currentStep < steps.length - 1) {
            handleNextStep(data);
          } else {
            onSubmit(data);
          }
        })}
      >
        <Stack spacing={2}>
          {currentStep === 0 && (
            <>
              <Controller
                control={control}
                name="Email"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.Email)}>
                    <InputLabel>Email address</InputLabel>
                    <OutlinedInput
                      {...field}
                      label="Email address"
                      type="email"
                    />
                    {errors.Email ? (
                      <FormHelperText>{errors.Email.message}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="Password"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.Password)}>
                    <InputLabel>Password</InputLabel>
                    <OutlinedInput
                      {...field}
                      label="Password"
                      type="password"
                    />
                    {errors.Password ? (
                      <FormHelperText>{errors.Password.message}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </>
          )}
          {currentStep === 1 && (
            <>
              <Controller
                control={control}
                name="Name"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.Name)}>
                    <InputLabel>Name</InputLabel>
                    <OutlinedInput {...field} label="Name" />
                    {errors.Name ? (
                      <FormHelperText>{errors.Name.message}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="Phone"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.Phone)}>
                    <InputLabel>Phone</InputLabel>
                    <OutlinedInput {...field} label="Phone" />
                    {errors.Phone ? (
                      <FormHelperText>{errors.Phone.message}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </>
          )}
          {currentStep === 2 && (
            <>
              <Controller
                control={control}
                name="CurrentSchool"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.CurrentSchool)}>
                    <InputLabel>School</InputLabel>
                    <OutlinedInput {...field} label="School" />
                    {errors.CurrentSchool ? (
                      <FormHelperText>
                        {errors.CurrentSchool.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="Type"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.Type)}>
                    <InputLabel>Type</InputLabel>
                    <Select {...field} label="Type">
                      {typeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.Type ? (
                      <FormHelperText>{errors.Type.message}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="Subjects"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.Subjects)}>
                    <InputLabel>Subjects</InputLabel>
                    <Select
                      multiple
                      value={
                        field.value.length > 0 ? field.value.split(" & ") : []
                      }
                      onChange={(e) => {
                        let selectedSubjects = [...e.target.value];

                        // Limit to two selections, replace the first if a third is selected
                        if (selectedSubjects.length > 2) {
                          selectedSubjects = selectedSubjects.slice(-2); // Keep only the last two selected
                        }

                        // Sort the subjects alphabetically before combining
                        selectedSubjects.sort();

                        field.onChange(selectedSubjects.join(" & ")); // Update the field value with sorted subjects
                      }}
                      renderValue={(selected) => selected.join(" & ")} // Display joined subjects
                    >
                      {subjectOptions.map((subject) => (
                        <MenuItem key={subject} value={subject}>
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.Subjects && (
                      <FormHelperText>{errors.Subjects.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </>
          )}
          {currentStep === 3 && (
            <LocationSelector control={control} errors={errors} />
          )}
          {isError && (
            <Alert
              color={
                isError.toString().includes("successful")
                  ? "success"
                  : "warning"
              }
            >
              {isError}
            </Alert>
          )}
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            {currentStep > 0 && (
              <Button
                variant="outlined"
                onClick={handlePreviousStep}
                disabled={isPending}
              >
                Back
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button
                variant="contained"
                onClick={handleSubmit(handleNextStep)}
                disabled={isPending}
              >
                {isPending ? <CircularProgress /> : "Next"}
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button disabled={isPending} type="submit" variant="contained">
                {isPending ? <CircularProgress /> : "Submit"}
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
    </Box>
  );
}
