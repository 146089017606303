import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Landing from "./Pages/landing";
import About from "./Pages/about";
import NoPage from "./Pages/nopage";
import Contact from "./Pages/contact";
import LoginPage from "./Pages/LoginPage";
import Dashboard from "./Components/dashboard/Dashboard";
import TermsAndConditions from "./Pages/terms";
import PrivacyPolicy from "./Pages/privacy";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<TermsAndConditions />} />
        <Route path="dashboard/*" element={<Dashboard />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
