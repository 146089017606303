import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import image from "../../Assets/about.png";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

export default function AboutHero(props) {
  return (
    <Box sx={{ py: 16, backgroundColor: "#f5f5f5" }}>
      <Container>
        <Grid spacing={4} className="div2equal" alignItems="center">
          <Grid
            sx={{ order: { xs: 1, md: 0 } }}
            item
            xs={12}
            md={6}
            className="left"
          >
            <Typography
              sx={{ fontWeight: 900, color: "#252C36" }}
              variant="h2"
              component="h1"
              gutterBottom
            >
              About <span style={{ color: "#FA541C" }}>TSwapMate</span>
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: "large", color: "#252C36" }}
              variant="body1"
              paragraph
              gutterBottom
            >
              At TSwapMate, we understand the importance of placing educators in
              environments where they can thrive and contribute most
              effectively. Our platform is dedicated to simplifying the process
              of teacher swapping, providing a seamless and efficient solution
              for educators employed by the Teachers Service Commission.
            </Typography>
          </Grid>
          <Grid
            sx={{ order: { xs: 0, md: 1 }, marginBottom: { xs: 10, md: 0 } }}
            item
            xs={12}
            md={6}
          >
            <img
              src={image}
              alt=""
              style={{
                maxHeight: "300px",
                objectFit: "contain",
                display: "block",
                margin: "auto",
                filter: "drop-shadow(0px 56px 80px rgba(0, 0, 0, 0.4))",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}


