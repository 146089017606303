import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import { Buffer } from "buffer";
import process from "process";

window.Buffer = Buffer;
window.process = process;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ScopedCssBaseline>
    <App />
  </ScopedCssBaseline>
);

reportWebVitals();
