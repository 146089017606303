import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function UserAccount({ open, onClose, currentUser }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Account Details
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Name:</strong> {currentUser.Name}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Email:</strong> {currentUser.Email}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Phone:</strong> {currentUser.Phone}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Current School:</strong> {currentUser.CurrentSchool}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Subjects:</strong> {currentUser.Subjects}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Type:</strong> {currentUser.Type}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Current Location:</strong>{" "}
            {`${currentUser.CurrentWard}, ${currentUser.CurrentSubCounty}, ${currentUser.CurrentCounty}`}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Preferred Location:</strong>{" "}
            {`${currentUser.WardTo || "N/A"}, ${
              currentUser.SubCountyTo || "N/A"
            }, ${currentUser.CountyTo || "N/A"}`}
          </Typography>
          {currentUser.Status && (
            <Typography variant="body1">
              <strong>Status:</strong> Active
            </Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
