import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  Box,
  Alert,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export default function ChangePassword({ open, onClose, currentUser }) {
  const [isError, setIsError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const rfOldPassword = useRef();
  const rfNewPassword = useRef();
  const rfConfirmNewPassword = useRef();

  useEffect(() => {
    setIsError("");
  }, [open]);

  const changePassword = () => {
    const d = {
      Password: rfOldPassword.current.value,
      NewPassword: rfNewPassword.current.value,
      ConfirmNewPassword: rfConfirmNewPassword.current.value,
    };

    setIsError("");

    if (!d.Password) return setIsError("Old password is required!");
    if (!d.NewPassword || d.NewPassword.length < 6)
      return setIsError("Password must be at least 6 characters!");
    if (!validatePassword(d.NewPassword, d.ConfirmNewPassword))
      return setIsError("Passwords do not match");

    setIsLoading(true);
    fetch(`/api/user/changepass`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((err) => {
            throw new Error(err.message || "Could not change password");
          });
        }
      })
      .then((data) => {
        setIsLoading(false);
        setIsError("Password changed successfully!");
        setTimeout(() => {
          onClose();
        }, 1500);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(err.message);
      });
  };

  const validatePassword = (newPassword, confirmNewPassword) => {
    return confirmNewPassword === newPassword;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Change Password for {currentUser.Name}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            inputRef={rfOldPassword}
            label="Enter Old Password *"
            type="password"
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
          />
          <TextField
            inputRef={rfNewPassword}
            label="Enter New Password *"
            type="password"
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
          />
          <TextField
            inputRef={rfConfirmNewPassword}
            label="Confirm New Password *"
            type="password"
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
          />
          {isError && (
            <Alert
              color={isError.includes("success") ? "success" : "warning"}
              sx={{ mb: 2 }}
            >
              {isError}
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={changePassword}
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
