import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import image from "../Assets/contact.svg";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Footer from "../Components/Utils/footer";
import Header from "../Components/Utils/header";
import "../Styles/about.scss";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useState } from "react";

export default function Contact(props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    const data = {
      Email: email,
      Name: name,
      Subject: subject,
      Message: message,
    };
    fetch("/api/messages/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("");
      })
      .then((data) => {
        if (data.success) {
          setSuccess(
            "Thank you fo contacting TSwapMate. We will respond as soon as possible."
          );
          setTimeout(() => {
            setSuccess("");
          }, 10000);
        } else {
          setError("Message not delivered. Try Again");
          setError(() => {
            setSuccess("");
          }, 10000);
        }
        setEmail("");
        setName("");
        setSubject("");
        setMessage("");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError("Message not delivered. Try Again");
        setEmail("");
        setName("");
        setSubject("");
        setMessage("");
        setError(() => {
          setError("");
        }, 10000);
      });
  };

  return (
    <div className="about">
      <Header />
      <Box sx={{ py: 16, backgroundColor: "#f5f5f5" }}>
        <Container>
          <Grid spacing={4} className="div2equal" alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src={image}
                alt=""
                style={{
                  maxHeight: "500px",
                  objectFit: "contain",
                  display: "block",
                  margin: "auto auto auto 0",
                  filter: "drop-shadow(0px 56px 80px rgba(0, 0, 0, 0.4))",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} className="left">
              <Typography
                sx={{ fontWeight: 900, color: "#252C36" }}
                variant="h2"
                component="h1"
                gutterBottom
              >
                Talk to <span style={{ color: "#FA541C" }}>US</span>
              </Typography>
              <Typography
                sx={{ fontWeight: 400, fontSize: "large", color: "#252C36" }}
                variant="body1"
                paragraph
                gutterBottom
              >
                We respond within 1 to 2 business days on the submitted email.
                However, we will strive to respond as soon as possible.
              </Typography>
              <form onSubmit={handleSubmit}>
                <FormControl
                  xs={12}
                  sx={{ width: "100%", marginBottom: "1rem" }}
                >
                  <InputLabel>Name</InputLabel>
                  <OutlinedInput
                    xs={12}
                    label="Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl
                  xs={12}
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  gutterBottom
                >
                  <InputLabel>Email address</InputLabel>
                  <OutlinedInput
                    xs={12}
                    label="Email address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormControl>

                <FormControl
                  xs={12}
                  sx={{ width: "100%", marginBottom: "1rem" }}
                >
                  <InputLabel>Subject</InputLabel>
                  <OutlinedInput
                    xs={12}
                    label="Subject"
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl
                  xs={12}
                  sx={{ width: "100%", marginBottom: "1rem" }}
                >
                  <InputLabel>Message</InputLabel>
                  <OutlinedInput
                    xs={12}
                    label="Message"
                    type="text"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </FormControl>
                <ColorButton
                  sx={{ my: 1, width: "100%" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : "Send"}
                </ColorButton>
              </form>

              {error && (
                <Alert color="warning" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
              {success && (
                <Alert color="success" sx={{ mt: 2 }}>
                  {success}
                </Alert>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Footer />
    </div>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#525ceb"),
  backgroundColor: "#525ceb",
  "&:hover": {
    backgroundColor: "#FA541C",
  },
}));
