import Bar from "../Components/Home/bar";
import FAQ from "../Components/Home/faq";
import Hero from "../Components/Home/hero";
import Intro from "../Components/Home/intro";
import Pricing from "../Components/Home/pricing";
import SwapCategories from "../Components/Home/swap_categories";
import Footer from "../Components/Utils/footer";
import Header from "../Components/Utils/header";
import "../Styles/home.scss";

export default function Landing(props) {
  return (
    <div className="home">
      <Header />
      <Hero />
      <Intro />
      <SwapCategories />
      <Bar />
      <Pricing />
      <FAQ />
      <Footer />
    </div>
  );
}
