import logo from "../../Assets/logo.png";

export default function Footer(props) {
  return (
    <div className="footer">
      <div className="container">
        <div className="links">
          <a href="/">Home</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms and Conditions</a>
        </div>
        <div className="center">
          <h1>TSwapMate</h1>
          <p>Copyright @{new Date().getFullYear()}. All rights reserved.</p>
        </div>
        <img src={logo} alt="" />
      </div>
    </div>
  );
}
