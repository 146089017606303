import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  TextField,
  Box,
  IconButton,
  Paper,
  Link,
} from "@mui/material";
import {
  Facebook,
  Twitter,
  School,
  LocationCity,
  Business,
  Public,
} from "@mui/icons-material";
import EnquiryDialog from "../../Pages/EnquiryDialog";

export default function SwapCategories(props) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "2rem", marginBottom: "2rem" }}
      gutterBottom
    >
      <Typography
        sx={{ textAlign: "center" }}
        variant="h4"
        style={{ marginBottom: "2rem" }}
        gutterBottom
      >
        Why Choose Our Teacher Swapping App?
      </Typography>
      <Grid container spacing={3} alignItems="stretch">
        {[
          {
            title: "Alternative Matches",
            description:
              "We simplify the process of swapping using subject combinations only.",
            icon: <School fontSize="large" />,
          },
          {
            title: "County to County",
            description:
              "We simplify the process of swapping between counties.",
            icon: <LocationCity fontSize="large" />,
          },
          {
            title: "Subcounty to Subcounty",
            description:
              "We simplify the process of swapping between subcounties.",
            icon: <Business fontSize="large" />,
          },
          {
            title: "Ward to Ward",
            description: "We simplify the process of swapping between wards.",
            icon: <Public fontSize="large" />,
          },
        ].map((feature, index) => (
          <Grid item xs={12} md={3} key={index} gutterBottom>
            <Card
              sx={{
                borderRadius: 6,
                boxShadow: "4px 4px 10px rgba(0,0,0,0.1)",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    height: "44px",
                    color: "#525ceb",
                  }}
                  display="flex"
                  justifyContent="center"
                  mb={2}
                >
                  {feature.icon}
                </Box>
                <Typography
                  sx={{ textAlign: "center", color: "#252C36" }}
                  variant="h6"
                  gutterBottom
                >
                  {feature.title}
                </Typography>
                <Typography sx={{ textAlign: "center" }} variant="body1">
                  {feature.description}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ margin: "1.5em auto 0 auto", display: "block" }}
                  onClick={() => {
                    handleOpenDialog();
                  }}
                >
                  Enquire
                </Button>
              </CardContent>
            </Card>
            <EnquiryDialog
              open={openDialog}
              onClose={handleCloseDialog}
              title={feature.title + " How it Works"}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
