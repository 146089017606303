import React from "react";
import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Header from "../Components/Utils/header";
import Footer from "../Components/Utils/footer";

const PrivacyPolicy = () => {
  return (
    <Box>
      <Header />
      <Container sx={{ marginTop: 10 }} maxWidth="md">
        <Box my={4}>
          <Typography variant="h5" gutterBottom>
            TSwapMate Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Last Updated:</strong> August 2024
          </Typography>

          <Typography variant="body1" paragraph>
            Welcome to TSwapMate, a service provided by TSwapMate Inc. ("we,"
            "us," or "our"). We value your privacy and are committed to
            protecting your personal information. This Privacy Policy explains
            how we collect, use, share, and protect your information when you
            use our services.
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            TSwapMate is a platform that allows teachers to swap schools based
            on subject combinations and location preferences. This Privacy
            Policy applies to all users of our platform, including teachers and
            other stakeholders.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            We collect various types of information to provide and improve our
            services to you.
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Personal Information"
                secondary="This includes your name, email address, phone number, and other contact details provided during registration."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Professional Information"
                secondary="This includes details about your current school, subjects you teach, and your location preferences (County, Sub-County, Ward)."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Usage Data"
                secondary="We collect data on how you use our platform, including your interactions, preferences, and the features you access."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Technical Information"
                secondary="This includes your IP address, browser type, device information, and other technical data collected when you access TSwapMate."
              />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            3. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We use your information to:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Provide and improve our services" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Facilitate school swaps based on your preferences" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Communicate with you, including sending notifications and updates" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Analyze usage and improve platform performance" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Ensure compliance with legal obligations and protect against fraud" />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            4. Data Sharing and Disclosure
          </Typography>
          <Typography variant="body1" paragraph>
            We take your privacy seriously and do not share your personal
            information with third parties except as necessary to provide our
            services, comply with the law, or protect our rights. We may share
            your information with:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Service Providers"
                secondary="Third-party service providers who assist in operating our platform and providing our services, such as hosting and payment processing."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Legal Compliance"
                secondary="Law enforcement or regulatory authorities if required by law or to protect our legal rights."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Business Transfers"
                secondary="In the event of a merger, acquisition, or sale of all or part of our assets, your information may be transferred as part of the transaction."
              />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            5. Data Security
          </Typography>
          <Typography variant="body1" paragraph>
            We implement robust security measures to protect your personal
            information. However, no online service can be completely secure,
            and we cannot guarantee absolute security. We encourage you to take
            steps to protect your information, such as using a strong password
            and keeping it confidential.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Your Rights and Choices
          </Typography>
          <Typography variant="body1" paragraph>
            You have certain rights regarding your personal information,
            including:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Access and Update"
                secondary="You can access and update your personal information through your account settings."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Data Portability"
                secondary="You can request a copy of your data in a structured, commonly used format."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Deletion"
                secondary="You can request the deletion of your account and personal information."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Opt-Out"
                secondary="You can opt-out of marketing communications at any time."
              />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            7. Data Retention
          </Typography>
          <Typography variant="body1" paragraph>
            We retain your personal information for as long as necessary to
            provide our services and fulfill the purposes outlined in this
            Privacy Policy. We may also retain certain information as required
            by law.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. International Data Transfers
          </Typography>
          <Typography variant="body1" paragraph>
            Your information may be transferred to and processed in countries
            outside of Kenya. We take steps to ensure that your data is treated
            securely and in accordance with this Privacy Policy, regardless of
            where it is processed.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Children's Privacy
          </Typography>
          <Typography variant="body1" paragraph>
            TSwapMate is not intended for use by individuals under the age of
            18. We do not knowingly collect personal information from children
            under 18. If we become aware that a child under 18 has provided us
            with personal information, we will take steps to delete such
            information.
          </Typography>

          <Typography variant="h6" gutterBottom>
            10. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our platform
            and updating the "Last Updated" date. Your continued use of
            TSwapMate after any changes indicates your acceptance of the updated
            Privacy Policy.
          </Typography>

          <Typography variant="h6" gutterBottom>
            11. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            Email: privacy@tswapmate.com <br />
            Address: [Your Company Address]
          </Typography>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default PrivacyPolicy;
