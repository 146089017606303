import React from "react";
import { Container, Typography, Button, Box, Paper } from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {
  AppRegistrationRounded,
  FlipToFront,
  Payment,
  Person2Rounded,
  SwapHorizontalCircleRounded,
} from "@mui/icons-material";

export default function HowItWorks(props) {
  return (
    <Box sx={{ py: 8, backgroundColor: "white" }}>
      <Container>
        <Grid container xs={12} alignItems="center">
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{ fontWeight: 900, color: "#252C36" }}
                variant="h2"
                component="h1"
                gutterBottom
              >
                How <span style={{ color: "#fa541c" }}>It</span> Works
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ fontWeight: 400, fontSize: "large", color: "#252C36" }}
                variant="body1"
                paragraph
                gutterBottom
              >
                Submit your TSC swap request within minutes!
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} my={2} alignItems="center">
            <Grid container spacing={5}>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: "flex" }}>
                  <AppRegistrationRounded
                    sx={{ fontSize: "44px", color: "#fa541c" }}
                    gutterBottom
                  />
                  <Typography
                    variant="h6"
                    sx={{ color: "#252C36", marginLeft: 1, marginTop: "auto" }}
                    gutterBottom
                  >
                    Sign Up
                  </Typography>
                </Box>

                <Typography variant="body1">
                  Create an account and log in.
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: "flex" }}>
                  <Person2Rounded
                    sx={{ fontSize: "44px", color: "#fa541c" }}
                    gutterBottom
                  />
                  <Typography
                    variant="h6"
                    sx={{ color: "#252C36", marginLeft: 1, marginTop: "auto" }}
                    gutterBottom
                  >
                    Profile Setup
                  </Typography>
                </Box>

                <Typography variant="body1">
                  Fill in your teaching credentials, current location, and
                  preferred transfer location.
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: "flex" }}>
                  <SwapHorizontalCircleRounded
                    sx={{ fontSize: "44px", color: "#fa541c" }}
                    gutterBottom
                  />
                  <Typography
                    variant="h6"
                    sx={{ color: "#252C36", marginLeft: 1, marginTop: "auto" }}
                    gutterBottom
                  >
                    Automatic Matching
                  </Typography>
                </Box>

                <Typography variant="body1">
                  Await automatic matching. You can also browse and select
                  alternative swaps
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: "flex" }}>
                  <Payment
                    sx={{ fontSize: "44px", color: "#fa541c" }}
                    gutterBottom
                  />
                  <Typography
                    variant="h6"
                    sx={{ color: "#252C36", marginLeft: 1, marginTop: "auto" }}
                    gutterBottom
                  >
                    Payment
                  </Typography>
                </Box>

                <Typography variant="body1">
                  Pay to start swap arrangements with the matched teacher.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
