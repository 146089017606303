import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";
import countyData from "../../Assets/county_data.json"; // Adjust the path based on your project structure

function LocationSelector({ control, errors }) {
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedSubCounty, setSelectedSubCounty] = useState("");
  const [selectedWard, setSelectedWard] = useState("");

  const [subCounties, setSubCounties] = useState([]);
  const [wards, setWards] = useState([]);

  useEffect(() => {
    if (selectedCounty) {
      setSubCounties(Object.keys(countyData[selectedCounty]));
      setSelectedSubCounty("");
      setSelectedWard("");
      setWards([]);
    }
  }, [selectedCounty]);

  useEffect(() => {
    if (selectedSubCounty && selectedCounty) {
      setWards(countyData[selectedCounty][selectedSubCounty]);
      setSelectedWard("");
    }
  }, [selectedSubCounty]);

  return (
    <>
      <Controller
        control={control}
        name="CurrentCounty"
        render={({ field }) => (
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.CurrentCounty)}
          >
            <InputLabel>County</InputLabel>
            <Select
              {...field}
              value={selectedCounty}
              onChange={(e) => {
                setSelectedCounty(e.target.value);
                field.onChange(e.target.value);
              }}
            >
              {Object.keys(countyData).map((county) => (
                <MenuItem key={county} value={county}>
                  {county}
                </MenuItem>
              ))}
            </Select>
            {errors.CurrentCounty && (
              <FormHelperText>{errors.CurrentCounty.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="CurrentSubCounty"
        render={({ field }) => (
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.CurrentSubCounty)}
            disabled={!selectedCounty}
          >
            <InputLabel>SubCounty</InputLabel>
            <Select
              {...field}
              value={selectedSubCounty}
              onChange={(e) => {
                setSelectedSubCounty(e.target.value);
                field.onChange(e.target.value);
              }}
            >
              {subCounties.map((subCounty) => (
                <MenuItem key={subCounty} value={subCounty}>
                  {subCounty}
                </MenuItem>
              ))}
            </Select>
            {errors.CurrentSubCounty && (
              <FormHelperText>{errors.CurrentSubCounty.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="CurrentWard"
        render={({ field }) => (
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.CurrentWard)}
            disabled={!selectedSubCounty}
          >
            <InputLabel>Ward</InputLabel>
            <Select
              {...field}
              value={selectedWard}
              onChange={(e) => {
                setSelectedWard(e.target.value);
                field.onChange(e.target.value);
              }}
            >
              {wards.map((ward) => (
                <MenuItem key={ward} value={ward}>
                  {ward}
                </MenuItem>
              ))}
            </Select>
            {errors.CurrentWard && (
              <FormHelperText>{errors.CurrentWard.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    </>
  );
}

export default LocationSelector;
