import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "./Navbar";
import Home from "./Home";
import Billing from "./Billing";
import Swaps from "./Swaps";
import Settings from "./Settings";

function Dashboard() {
  const [user, setUser] = React.useState(null);
  return (
    <Box sx={{ display: "flex" }}>
      <Navbar setUser={setUser} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="billing" element={<Billing />} />
          <Route path="swaps" element={<Swaps />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default Dashboard;
