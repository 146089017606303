import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ArrowDown as ArrowDownIcon } from "@phosphor-icons/react";
import { ArrowUp as ArrowUpIcon } from "@phosphor-icons/react";
import { TransferWithinAStation } from "@mui/icons-material";

export function TopItem({ title, diff, trend, value }) {
  const TrendIcon = trend === "up" ? ArrowUpIcon : ArrowDownIcon;
  const trendColor = trend === "up" ? "green" : "orange";

  return (
    <Card
      style={{
        borderRadius: "16px",
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
        height: "100%",
      }}
    >
      <CardContent sx={{ position: "relative", height: "100%" }}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between" }}
          spacing={1}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              {title}
            </Typography>
            <Typography variant="h4">{value}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "#fa541c",
              height: "32px",
              width: "32px",
              position: "absolute",
              bottom: 10,
              right: 10,
            }}
          >
            <TransferWithinAStation fontSize="16px" />
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
}
