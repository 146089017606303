import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import image from "../../Assets/image.png";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

export default function Hero(props) {
  return (
    <Box sx={{ py: 16, backgroundColor: "#f5f5f5" }}>
      <Container>
        <Grid spacing={4} className="div2equal" alignItems="center">
          <Grid
            sx={{ order: { xs: 1, md: 0 } }}
            item
            xs={12}
            md={6}
            className="left"
          >
            <Typography
              sx={{ fontWeight: 900, color: "#252C36" }}
              variant="h2"
              component="h1"
              gutterBottom
            >
              Welcome to <span style={{ color: "#FA541C" }}>TSwapMate</span>
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: "x-large", color: "#252C36" }}
              variant="body1"
              paragraph
              gutterBottom
            >
              Revolutionizing Teacher Swapping for a Better Educational
              Landscape!
            </Typography>
            <ColorButton
              onClick={() => {
                window.location.href = "/login";
              }}
              sx={{ my: 2 }}
              variant="contained"
              color="primary"
            >
              Getting Started
            </ColorButton>
          </Grid>
          <Grid
            sx={{ order: { xs: 0, md: 1 }, marginBottom: { xs: 10, md: 0 } }}
            item
            xs={12}
            md={6}
          >
            <img
              src={image}
              alt=""
              style={{
                maxHeight: "300px",
                objectFit: "contain",
                display: "block",
                margin: "auto",
                filter: "drop-shadow(0px 56px 80px rgba(0, 0, 0, 0.4))",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#525ceb"),
  backgroundColor: "#525ceb",
  "&:hover": {
    backgroundColor: "#FA541C",
  },
}));
