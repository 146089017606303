import React from "react";
import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Header from "../Components/Utils/header";
import Footer from "../Components/Utils/footer";

const TermsAndConditions = () => {
  return (
    <Box>
      <Header />
      <Container sx={{ marginTop: 10 }} maxWidth="md">
        <Box my={4}>
          <Typography variant="h5" gutterBottom>
            TSwapMate Terms and Conditions
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Last Updated:</strong> August 2024
          </Typography>

          <Typography variant="body1" paragraph>
            Welcome to TSwapMate, a platform provided by TSwapMate Inc. ("we,"
            "us," or "our"). By accessing or using our platform, you agree to
            comply with and be bound by the following terms and conditions
            ("Terms"). Please read them carefully before using TSwapMate.
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By using TSwapMate, you agree to these Terms, our Privacy Policy,
            and any other policies we may implement from time to time. If you do
            not agree with any part of these Terms, you must not use our
            platform.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Eligibility
          </Typography>
          <Typography variant="body1" paragraph>
            TSwapMate is intended for use by individuals who are at least 18
            years old. By using our platform, you represent and warrant that you
            are at least 18 years of age and have the legal capacity to enter
            into this agreement.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. User Registration
          </Typography>
          <Typography variant="body1" paragraph>
            To access certain features of TSwapMate, you may be required to
            register and create an account. You agree to provide accurate,
            current, and complete information during the registration process
            and to update such information as necessary. You are responsible for
            safeguarding your account credentials and for any activities or
            actions under your account.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Use of the Platform
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to use TSwapMate only for lawful purposes and in
            accordance with these Terms. You agree not to:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Violate any applicable laws or regulations." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Use the platform for any unauthorized or fraudulent purpose." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Interfere with or disrupt the operation of the platform." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Post or transmit any content that is unlawful, harmful, or otherwise objectionable." />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            5. School Swapping
          </Typography>
          <Typography variant="body1" paragraph>
            TSwapMate facilitates school swapping based on your preferences.
            However, we do not guarantee that a suitable swap will be available
            or that a swap will be successful. You are solely responsible for
            any decisions made regarding school swapping.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Fees and Payments
          </Typography>
          <Typography variant="body1" paragraph>
            Certain services on TSwapMate may require payment. If you choose to
            use a paid service, you agree to provide accurate and complete
            payment information and to pay all applicable fees. We may use
            third-party payment processors to facilitate transactions, and by
            making a payment, you agree to their terms and conditions as well.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            All content on TSwapMate, including text, graphics, logos, and
            software, is the property of TSwapMate Inc. or its licensors and is
            protected by intellectual property laws. You may not reproduce,
            distribute, or create derivative works from any content on the
            platform without our express written permission.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Termination
          </Typography>
          <Typography variant="body1" paragraph>
            We may terminate or suspend your access to TSwapMate at any time,
            without notice, if you violate these Terms or for any other reason.
            Upon termination, your right to use the platform will cease
            immediately.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            To the fullest extent permitted by law, TSwapMate and its
            affiliates, officers, employees, and agents shall not be liable for
            any direct, indirect, incidental, special, or consequential damages
            arising out of or in connection with your use of the platform,
            including, but not limited to, any errors or omissions in any
            content, or any loss or damage of any kind incurred as a result of
            the use of the platform.
          </Typography>

          <Typography variant="h6" gutterBottom>
            10. Indemnification
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to indemnify, defend, and hold harmless TSwapMate and its
            affiliates, officers, employees, and agents from and against any
            claims, liabilities, damages, losses, and expenses, including
            reasonable attorneys' fees, arising out of or in any way connected
            with your access to or use of the platform, your violation of these
            Terms, or your infringement of any intellectual property or other
            rights of any third party.
          </Typography>

          <Typography variant="h6" gutterBottom>
            11. Governing Law and Dispute Resolution
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms are governed by and construed in accordance with the
            laws of Kenya. Any disputes arising out of or relating to these
            Terms or your use of TSwapMate shall be resolved through binding
            arbitration in accordance with the laws of Kenya.
          </Typography>

          <Typography variant="h6" gutterBottom>
            12. Changes to the Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We may update these Terms from time to time. We will notify you of
            any changes by posting the new Terms on our platform and updating
            the "Last Updated" date. Your continued use of TSwapMate after any
            changes indicates your acceptance of the updated Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            13. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or concerns about these Terms, please
            contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            Email: support@tswapmate.com <br />
            Address: [Your Company Address]
          </Typography>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default TermsAndConditions;
