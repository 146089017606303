import React, { useState } from "react";
import {
  Typography,
  Container,
  Box,
  IconButton,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const FAQItem = ({ question, answer, expanded, onClick }) => (
  <Box
    mb={1}
    sx={{
      transition: "background-color 0.3s ease",
      "&:hover": {
        backgroundColor: "#f0f0f0", // Light grey background on hover
      },
    }}
  >
    <Box
      display="flex"
      alignItems="center"
      sx={{
        padding: "8px",
        borderRadius: "8px", // Rounded corners for better visual effect
      }}
    >
      <Typography
        variant="p"
        sx={{
          flexGrow: 1,
          cursor: "pointer",
          color: expanded ? "#fa541c" : "#252C36",
          fontWeight: 500,
        }}
        onClick={onClick}
      >
        {question}
      </Typography>
      <IconButton onClick={onClick}>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Box>
    <Collapse in={expanded}>
      <Typography variant="p" sx={{ padding: "10px" }} paragraph>{answer}</Typography>
    </Collapse>
  </Box>
);

export default function FAQ() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How do I sign up?",
      answer:
        "Click on the 'Get Started' button on the top of the page and fill in the required details to create an account.",
    },
    {
      question: "What are the requirements for a transfer?",
      answer:
        "Ensure your profile is complete with all necessary credentials and submit a transfer request based on available opportunities.",
    },
    {
      question: "Can I cancel a transfer request?",
      answer:
        "Yes, you can cancel a transfer request before it is approved. Go to your profile and select the request you wish to cancel.",
    },
    {
      question: "How long does it take to process a transfer?",
      answer:
        "The processing time for a transfer request typically ranges from 1 to 2 weeks, depending on the availability of the requested positions and administrative processes.",
    },
    {
      question: "How can I track the status of my transfer request?",
      answer:
        "You can track the status of your transfer request by logging into your account and checking the 'My Requests' section.",
    },
    {
      question: "What should I do if I encounter issues with the application?",
      answer:
        "If you encounter any issues with the application, please contact our support team through the 'Help' section or email us at support@example.com.",
    },
    {
      question: "Are there any fees associated with transfer requests?",
      answer:
        "Yes, there are nominal fees associated with different types of transfers. The fees are displayed on the request form and vary based on the type of transfer.",
    },
    {
      question: "Can I update my profile details after signing up?",
      answer:
        "Yes, you can update your profile details at any time by going to the 'Profile' section in your account settings.",
    },
    // Add more FAQs as needed
  ];

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "3rem", marginBottom: "3rem" }}
    >
      <Typography
        sx={{ textAlign: "center" }}
        variant="h4"
        style={{ marginBottom: "2rem" }}
        gutterBottom
      >
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <FAQItem
          key={index}
          question={faq.question}
          answer={faq.answer}
          expanded={expandedIndex === index}
          onClick={() => handleToggle(index)}
        />
      ))}
    </Container>
  );
}
