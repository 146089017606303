import React, { useEffect, useState } from "react";
import { Phone, Message, WhatsApp, Email } from "@mui/icons-material";
import {
  Box,
  ButtonGroup,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Divider,
  Alert,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import { Close } from "@mui/icons-material";

function SwapDashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeTab, setActiveTab] = useState("County Match");
  const [swapsData, setSwapsData] = useState({
    alternative: [],
    ward: [],
    subcounty: [],
    county: [],
  });
  const [matchCounts, setMatchCounts] = useState({
    alternative: 0,
    ward: 0,
    subcounty: 0,
    county: 0,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedSwap, setSelectedSwap] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [mpesaText, setMpesaText] = useState("");
  const [isError, setIsError] = useState("");

  const paymentAmounts = {
    "Alternative Match": 1000,
    "Ward Match": 2500,
    "SubCounty Match": 2000,
    "County Match": 1500,
  };

  useEffect(() => {
    const fetchSwapsCounts = async () => {
      try {
        const res = await fetch("/api/swaps/mystats", {
          credentials: "include",
        });

        if (res.ok) {
          const data = await res.json();
          setMatchCounts(data);
        } else if (res.status === 401) {
          window.location.href = "/";
          throw new Error("Unauthorized access. Please log in.");
        } else if (res.status === 403) {
          window.location.href = "/";
          throw new Error(
            "Forbidden access. You do not have permission to view this resource."
          );
        } else {
          throw new Error("An error occurred while fetching data.");
        }
      } catch (error) {
        console.error("Error fetching swaps data:", error);
      }
    };

    fetchSwapsCounts();
  }, []);

  useEffect(() => {
    if (matchCounts.countyMatches > 0) {
      setActiveTab("County Match");
    } else if (matchCounts.subCountyMatches > 0) {
      setActiveTab("SubCounty Match");
    } else if (matchCounts.wardMatches > 0) {
      setActiveTab("Ward Match");
    } else if (matchCounts.alternativeMatches > 0) {
      setActiveTab("Alternative Match");
    }
  }, [matchCounts]);

  useEffect(() => {
    const fetchSwapsByType = async () => {
      const typeMap = {
        "Alternative Match": "alternative",
        "Ward Match": "ward",
        "SubCounty Match": "subcounty",
        "County Match": "county",
      };

      const type = typeMap[activeTab];
      setIsLoading(true);
      try {
        const res = await fetch(
          `/api/swaps/myswapsdata/${type}?page=${page}&limit=10`,
          {
            credentials: "include",
          }
        );

        if (res.ok) {
          const data = await res.json();
          setIsLoading(false);
          setSwapsData((prevData) => ({
            ...prevData,
            [type]: data.matches,
          }));
          setTotalPages(data.totalPages);
        } else if (res.status === 401) {
          setIsLoading(false);
          window.location.href = "/";
          throw new Error("Unauthorized access. Please log in.");
        } else if (res.status === 403) {
          setIsLoading(false);
          window.location.href = "/";
          throw new Error(
            "Forbidden access. You do not have permission to view this resource."
          );
        } else {
          setIsLoading(false);
          throw new Error("An error occurred while fetching data.");
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching swap matches:", error);
      }
    };

    fetchSwapsByType();
  }, [activeTab, page]);

  const handleRowClick = (swap) => {
    setSelectedSwap(swap);
    setMpesaText("");
    setIsError("");
  };

  const validateMpesaText = (text) => {
    const amount = selectedSwap ? paymentAmounts[selectedSwap?.Criteria] : 0;
    const regex = new RegExp(
      `Confirmed. Ksh${amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} paid to Jackline Musumbi. on (\\d{1,2}/\\d{1,2}/\\d{2,4}) at (\\d{1,2}:\\d{2} [AP]M)\\.New M-PESA balance is Ksh.* Transaction cost, Ksh0.00.`
    );
    const match = text.match(regex);
    return match && match.length > 0;
  };

  const handlePaymentSubmit = () => {
    setIsError("");
    if (!validateMpesaText(mpesaText)) {
      setIsError(
        "Invalid MPESA message. Ensure the amount, recipient, and format are correct."
      );
      return;
    }

    setIsLoading(true);
    setIsError("");

    const payload = {
      SwapID: selectedSwap?.ID,
      TeacherID: selectedSwap?.TeacherID,
      Amount: paymentAmounts[selectedSwap?.Criteria],
      MpesaCode: mpesaText,
    };

    fetch(`/api/payments`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          window.location.href = "/";
          throw new Error("Unauthorized access. Please log in.");
        } else if (res.status === 403) {
          window.location.href = "/";
          throw new Error(
            "Forbidden access. You do not have permission to view this resource."
          );
        } else {
          throw new Error("An error occurred while fetching data.");
        }
      })
      .then((data) => {
        setIsLoading(false);
        if (data.message) {
          setIsError(data.message);
          setTimeout(() => {
            setSelectedSwap(null);
            setRefresh(!refresh);
          }, 1000);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError("Payment failed! Please try again.");
      });
  };

  const renderTable = (data) =>
    !isMobile ? (
      <TableContainer component={Paper}>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Swap Criteria</TableCell>
              <TableCell>Other Info</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Payment</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((swap, index) => (
              <SwapItem
                key={index}
                index={index}
                page={page}
                handleRowClick={handleRowClick}
                refresh={refresh}
                swap={swap}
                isMobile={isMobile} // Pass isMobile to SwapItem
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      data.map((swap, index) => (
        <SwapItem
          key={index}
          index={index}
          page={page}
          handleRowClick={handleRowClick}
          refresh={refresh}
          swap={swap}
          isMobile={isMobile} // Pass isMobile to SwapItem
        />
      ))
    );

  return (
    <Box sx={{ marginTop: isMobile ? "5rem" : "5rem" }}>
      <ButtonGroup
        variant="contained"
        orientation={isMobile ? "vertical" : "horizontal"}
        fullWidth={isMobile}
        sx={{ boxShadow: "none" }}
      >
        <Button
          sx={{
            textTransform: "capitalize",
          }}
          onClick={() => setActiveTab("County Match")}
          startIcon={
            <Chip
              color={matchCounts.countyMatches > 0 ? "success" : "warning"}
              label={matchCounts.countyMatches}
            />
          }
          variant={activeTab === "County Match" ? "contained" : "outlined"}
        >
          County Match
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => setActiveTab("SubCounty Match")}
          startIcon={
            <Chip
              color={matchCounts.subCountyMatches > 0 ? "success" : "warning"}
              label={matchCounts.subCountyMatches}
            />
          }
          variant={activeTab === "SubCounty Match" ? "contained" : "outlined"}
        >
          SubCounty Match
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => setActiveTab("Ward Match")}
          startIcon={
            <Chip
              color={matchCounts.wardMatches > 0 ? "success" : "warning"}
              label={matchCounts.wardMatches}
            />
          }
          variant={activeTab === "Ward Match" ? "contained" : "outlined"}
        >
          Ward Match
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => setActiveTab("Alternative Match")}
          startIcon={
            <Chip
              color={matchCounts.alternativeMatches > 0 ? "success" : "warning"}
              label={matchCounts.alternativeMatches}
            />
          }
          variant={activeTab === "Alternative Match" ? "contained" : "outlined"}
        >
          Alternative Match
        </Button>
      </ButtonGroup>

      <Box sx={{ minHeight: "50vh" }} marginTop={2}>
        {activeTab === "Alternative Match" &&
          renderTable(swapsData.alternative)}
        {activeTab === "Ward Match" && renderTable(swapsData.ward)}
        {activeTab === "SubCounty Match" && renderTable(swapsData.subcounty)}
        {activeTab === "County Match" && renderTable(swapsData.county)}
        {isLoading && <CircularProgress />}
      </Box>
      <Divider />
      <Box
        marginTop={2}
        display="flex"
        justifyContent="center"
        flexDirection={isMobile ? "column" : "row"}
        alignItems="center"
      >
        <Button
          fullWidth={isMobile}
          disabled={page <= 1}
          onClick={() => setPage((prevPage) => prevPage - 1)}
        >
          Previous
        </Button>
        <Box marginX={2} my={isMobile ? 1 : "auto"} sx={{ fontSize: "small" }}>
          Page {page} of {totalPages}
        </Box>
        <Button
          fullWidth={isMobile}
          disabled={page >= totalPages}
          onClick={() => setPage((prevPage) => prevPage + 1)}
        >
          Next
        </Button>
      </Box>

      {/* Payment Dialog */}
      <Dialog
        open={Boolean(selectedSwap)}
        onClose={() => setSelectedSwap(null)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Make Payment for {selectedSwap?.Criteria}</DialogTitle>
        <DialogContent>
          <Divider />
          <Typography variant="h6" color="green">
            M-PESA Paybill
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Account Name: <strong>Jackline Musumbi</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Amount:{" "}
            <strong>
              Ksh{" "}
              {selectedSwap
                ? paymentAmounts[selectedSwap?.Criteria].toLocaleString(
                    undefined,
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  )
                : 0}
            </strong>
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Paybill Number: <strong>247 247</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Account Number: <strong>069 017 661 8882</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            After payment, enter the full confirmation message below.
          </Typography>
          {isError && (
            <Alert
              color={isError.includes("Invalid") ? "error" : "success"}
              sx={{ mb: 2 }}
            >
              {isError}
            </Alert>
          )}
          <TextField
            label="M-PESA Confirmation Text"
            variant="outlined"
            fullWidth
            multiline
            rows={isMobile ? 2 : 4}
            value={mpesaText}
            onChange={(e) => {
              setIsError("");
              setMpesaText(e.target.value);
            }}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedSwap(null)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handlePaymentSubmit}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit Payment"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SwapDashboard;

const SwapItem = (props) => {
  const {
    swap,
    index,
    page,
    handleRowClick,
    refresh,
    isMobile, // Access isMobile prop
  } = props;

  const [mePay, setMePay] = useState(null);
  const [tPay, setTPay] = useState(null);
  const [teacherDetails, setTeacherDetails] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetch(`/api/payments/getpayments/${swap.ID}/${swap.MatchedSwapID}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          window.location.href = "/";
          throw new Error("Unauthorized access. Please log in.");
        } else if (res.status === 403) {
          window.location.href = "/";
          throw new Error(
            "Forbidden access. You do not have permission to view this resource."
          );
        } else {
          throw new Error("An error occurred while fetching data.");
        }
      })
      .then((data) => {
        if (data.MePay.length > 0) {
          setMePay(data.MePay[0]);
        }
        if (data.TPay.length > 0) {
          setTPay(data.TPay[0]);
        }
      })
      .catch((e) => {});
  }, [swap, refresh]);

  const handleFetchTeacherDetails = () => {
    setLoading(true);
    fetch(`/api/teachers/byid/${swap.MatchedWith}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Failed to fetch teacher details.");
        }
      })
      .then((data) => {
        setLoading(false);
        setTeacherDetails(data);
        setOpenDialog(true);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClick = () => {
    if (!mePay) {
      handleRowClick(swap);
    } else if (mePay?.Status === "Confirmed" && tPay?.Status === "Confirmed") {
      handleFetchTeacherDetails();
    }
  };

  const getButtonText = () => {
    if (!mePay) {
      return "Pay Now";
    } else if (mePay?.Status === "Confirmed" && tPay?.Status === "Confirmed") {
      return "View Swap Details";
    } else {
      return "No Action";
    }
  };

  if (isMobile) {
    return (
      <>
        {" "}
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between">
              <Chip label={index + (page - 1) * 10 + 1} />
              <Typography variant="body2">
                {swap.updatedAt.split("T")[0]}
              </Typography>
            </Box>

            <Typography variant="h6" sx={{ mt: 1 }}>
              {swap.Criteria}
            </Typography>

            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Teacher Details:</strong> {swap.Subjects} - {swap.Type}
            </Typography>

            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>To Location:</strong> {swap.CountyTo}, {swap.SubCountyTo},{" "}
              {swap.WardTo}
            </Typography>

            <Typography variant="body2" sx={{ mb: 2 }}>
              <strong>Status:</strong> {swap.Status}
            </Typography>

            <Box display="flex" flexDirection="column">
              <Chip
                sx={{ fontSize: "x-small", mb: 1 }}
                label={
                  mePay
                    ? mePay.Status === "Confirmed"
                      ? "You: Paid"
                      : "You: " + mePay.Status
                    : "You: Not Paid"
                }
                color={
                  mePay
                    ? mePay.Status === "Confirmed"
                      ? "success"
                      : "warning"
                    : "error"
                }
              />
              <Chip
                sx={{ fontSize: "x-small", mb: 2 }}
                label={
                  tPay
                    ? tPay.Status === "Confirmed"
                      ? "Swap Mate: Paid"
                      : "Swap Mate: " + tPay.Status
                    : "Swap Mate: Not Paid"
                }
                color={
                  tPay
                    ? tPay.Status === "Confirmed"
                      ? "success"
                      : "warning"
                    : "error"
                }
              />
            </Box>

            <Button
              variant="contained"
              onClick={handleClick}
              disabled={
                loading ||
                (mePay && mePay.Status !== "Confirmed") ||
                (tPay &&
                  tPay.Status !== "Confirmed" &&
                  tPay.Status !== "Pending" &&
                  tPay.Status !== "System Design")
              }
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : getButtonText()}
            </Button>
          </CardContent>
        </Card>
        {teacherDetails && (
          <Dialog
            fullWidth
            maxWidth="sm"
            open={openDialog}
            onClose={handleCloseDialog}
          >
            <DialogTitle
              sx={{ backgroundColor: "green", color: "white", display: "flex" }}
            >
              <Typography sx={{ flexGrow: 1 }} variant="h5">
                Congratulations!!!
              </Typography>
              <Close
                sx={{ margin: "auto", cursor: "pointer" }}
                onClick={handleCloseDialog}
              />
            </DialogTitle>
            <DialogContent sx={{ marginBottom: 0, p: 0 }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  gap: 1,
                }}
              >
                <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                  Name
                </Typography>
                <Typography sx={{ margin: "auto", width: "100%" }}>
                  {teacherDetails.Name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  gap: 1,
                }}
              >
                <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                  Subjects
                </Typography>
                <Typography sx={{ margin: "auto", width: "100%" }}>
                  {teacherDetails.Subjects}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  gap: 1,
                }}
              >
                <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                  Type
                </Typography>
                <Typography sx={{ margin: "auto", width: "100%" }}>
                  {teacherDetails.Type}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  gap: 1,
                }}
              >
                <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                  School
                </Typography>
                <Typography sx={{ margin: "auto", width: "100%" }}>
                  {teacherDetails.CurrentSchool}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  gap: 1,
                }}
              >
                <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                  Location
                </Typography>
                <Typography sx={{ margin: "auto", width: "100%" }}>
                  {teacherDetails.CurrentCounty},{" "}
                  {teacherDetails.CurrentSubCounty},{" "}
                  {teacherDetails.CurrentWard}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  gap: 1,
                }}
              >
                <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                  Phone
                </Typography>
                <Typography sx={{ margin: "auto", width: "100%" }}>
                  {teacherDetails.Phone}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions sx={{ marginTop: 0 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open(
                    `tel:+254${teacherDetails.Phone.slice(1)}`,
                    "_self"
                  )
                }
              >
                <Phone />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  window.open(
                    `sms:+254${teacherDetails.Phone.slice(1)}`,
                    "_self"
                  )
                }
              >
                <Message />
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  window.open(
                    `https://wa.me/+254${teacherDetails.Phone.slice(1)}`,
                    "_blank"
                  )
                }
              >
                <WhatsApp />
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() =>
                  window.open(`mailto:${teacherDetails.Email}`, "_self")
                }
              >
                <Email />
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Chip label={index + (page - 1) * 10 + 1}></Chip>
        </TableCell>

        <TableCell>
          {swap.Criteria}
          <Typography variant="body2">
            {swap.updatedAt.split("T")[0]}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{swap.Subjects}</Typography>
          <Typography variant="body2">{swap.Type}</Typography>
        </TableCell>

        <TableCell>
          {swap.CountyTo}, {swap.SubCountyTo}, {swap.WardTo}
        </TableCell>
        <TableCell>{swap.Status}</TableCell>
        <TableCell>
          <Chip
            sx={{ fontSize: "small", display: "flex", marginBottom: "5px" }}
            label={
              mePay
                ? mePay.Status === "Confirmed"
                  ? "You: Paid"
                  : "You: " + mePay.Status
                : "You: Not Paid"
            }
            color={
              mePay
                ? mePay.Status === "Confirmed"
                  ? "success"
                  : "warning"
                : "error"
            }
          />
          <Chip
            sx={{ fontSize: "small", display: "flex", marginBottom: "5px" }}
            label={
              tPay
                ? tPay.Status === "Confirmed"
                  ? "Swap Mate: Paid"
                  : "Swap Mate: " + tPay.Status
                : "Swap Mate: Not Paid"
            }
            color={
              tPay
                ? tPay.Status === "Confirmed"
                  ? "success"
                  : "warning"
                : "error"
            }
          />
        </TableCell>

        <TableCell>
          <Button
            variant="contained"
            onClick={handleClick}
            disabled={
              loading ||
              (mePay && mePay.Status !== "Confirmed") ||
              (tPay &&
                tPay.Status !== "Confirmed" &&
                tPay.Status !== "Pending" &&
                tPay.Status !== "System Design")
            }
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : getButtonText()}
          </Button>
        </TableCell>
      </TableRow>

      {teacherDetails && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle
            sx={{ backgroundColor: "green", color: "white", display: "flex" }}
          >
            <Typography sx={{ flexGrow: 1 }} variant="h5">
              Congratulations!!!
            </Typography>
            <Close
              sx={{ margin: "auto", cursor: "pointer" }}
              onClick={handleCloseDialog}
            />
          </DialogTitle>
          <DialogContent p={0} sx={{ marginBottom: 0 }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 2fr",
                gap: 1,
              }}
            >
              <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                Name
              </Typography>
              <Typography sx={{ margin: "auto", width: "100%" }}>
                {teacherDetails.Name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 2fr",
                gap: 1,
              }}
            >
              <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                Subjects
              </Typography>
              <Typography sx={{ margin: "auto", width: "100%" }}>
                {teacherDetails.Subjects}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 2fr",
                gap: 1,
              }}
            >
              <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                Type
              </Typography>
              <Typography sx={{ margin: "auto", width: "100%" }}>
                {teacherDetails.Type}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 2fr",
                gap: 1,
              }}
            >
              <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                School
              </Typography>
              <Typography sx={{ margin: "auto", width: "100%" }}>
                {teacherDetails.CurrentSchool}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 2fr",
                gap: 1,
              }}
            >
              <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                Location
              </Typography>
              <Typography sx={{ margin: "auto", width: "100%" }}>
                {teacherDetails.CurrentCounty},{" "}
                {teacherDetails.CurrentSubCounty}, {teacherDetails.CurrentWard}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 2fr",
                gap: 1,
              }}
            >
              <Typography sx={{ backgroundColor: "#F1F3C2", p: "5px 10px" }}>
                Phone
              </Typography>
              <Typography sx={{ margin: "auto", width: "100%" }}>
                {teacherDetails.Phone}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ marginTop: 0 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Phone />}
              onClick={() =>
                window.open(`tel:${teacherDetails.Phone}`, "_self")
              }
            >
              Call
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Message />}
              onClick={() =>
                window.open(`sms:${teacherDetails.Phone}`, "_self")
              }
            >
              Message
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<WhatsApp />}
              onClick={() =>
                window.open(`https://wa.me/${teacherDetails.Phone}`, "_blank")
              }
            >
              WhatsApp
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<Email />}
              onClick={() =>
                window.open(`mailto:${teacherDetails.Email}`, "_self")
              }
            >
              Email
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
