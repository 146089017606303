import React from "react";
import { Typography, Container, Stack, Button } from "@mui/material";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";

export default function Intro(params) {
  return (
    <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
      <Stack
        sx={{
          backgroundColor: "#F1F3F5",
          my: 10,
          borderRadius: 8,
          padding: 5,
          alignItems: "center", // Center content horizontally
          justifyContent: "center", // Center content vertically
          textAlign: "center", // Center text inside Stack
        }}
        spacing={3}
      >
        <TransferWithinAStationIcon
          sx={{
            fontSize: "150px",
            color: "#525ceb",
          }}
        />
        <Typography sx={{ fontWeight: 900, color: "#252C36" }} variant="h4">
          TSC <span style={{ color: "#FA541C" }}>Teacher Swaps</span> with Ease
        </Typography>
        <Typography sx={{ fontWeight: 400, color: "#252C36" }} variant="body1">
          Effortless swapping between schools, counties, sub-counties, and
          wards.
        </Typography>
        <Button
          onClick={() => {
            window.location.href = "/login";
          }}
          variant="contained"
        >
          Start Now
        </Button>
      </Stack>
    </Container>
  );
}
