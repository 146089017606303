import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";

const typeOptions = ["Secondary", "JSS", "Primary"];
const subjectOptions = [
  "English",
  "Literature",
  "Mathematics",
  "Kiswahili",
  "Physics",
  "Chemistry",
  "Biology",
  "History",
  "Geography",
  "CRE",
  "IRE",
  "HRE",
  "Business Studies",
  "French",
  "German",
  "Arabic",
  "Kenya Sign Language",
  "Music",
  "Home Science",
  "Art & Design",
  "Agriculture",
  "Metal Work",
  "Wood Work",
  "Building Construction",
  "Electricity",
  "Power Mechanic",
  "Drawing & Design",
  "Aviation Technology",
  "Computer Studies",
];

export default function Settings() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Teacher profile states
  const [teacherData, setTeacherData] = useState(null);
  const [teacherError, setTeacherError] = useState(null);
  const [isTeacherLoading, setIsTeacherLoading] = useState(false);

  // Fetch teacher profile on load
  useEffect(() => {
    fetch(`/api/teachers/profile`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        setTeacherData(data);
      })
      .catch((error) => setTeacherError("Failed to load teacher profile"));
  }, []);

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    setError(null);
    if (
      !oldPassword ||
      newPassword.length < 6 ||
      newPassword !== confirmPassword
    ) {
      setError("Please enter valid passwords and ensure they match.");
      return;
    }
    setIsLoading(true);

    fetch(`/api/user/changepass`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Password: oldPassword, NewPassword: newPassword }),
    })
      .then((response) => response.json())
      .then(() => {
        setIsLoading(false);
        setError("Password updated successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Failed to update password.");
      });
  };

  const handleTeacherSubmit = (event) => {
    event.preventDefault();
    setIsTeacherLoading(true);

    fetch(`/api/teachers/profile`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(teacherData),
    })
      .then((response) => response.json())
      .then(() => {
        setIsTeacherLoading(false);
        setTeacherError("Teacher profile updated successfully!");
      })
      .catch(() => {
        setIsTeacherLoading(false);
        setTeacherError("Failed to update teacher profile.");
      });
  };

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        {/* Teacher Profile Update Form */}
        <form onSubmit={handleTeacherSubmit}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <CardHeader
              subheader="Update teacher profile"
              title="Teacher Profile"
            />
            <Divider />
            <CardContent>
              <Stack spacing={3} sx={{ maxWidth: "sm" }}>
                <FormControl fullWidth>
                  <InputLabel shrink={Boolean(teacherData?.Name)}>
                    Name
                  </InputLabel>
                  <OutlinedInput
                    label="Name"
                    value={teacherData?.Name || ""}
                    onChange={(e) =>
                      setTeacherData({ ...teacherData, Name: e.target.value })
                    }
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel shrink={Boolean(teacherData?.Type)}>
                    Type
                  </InputLabel>
                  <Select
                    label="Type"
                    value={teacherData?.Type || ""}
                    onChange={(e) =>
                      setTeacherData({ ...teacherData, Type: e.target.value })
                    }
                  >
                    {typeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Subjects</InputLabel>
                  <Select
                    multiple
                    value={
                      teacherData && teacherData?.Subjects !== undefined
                        ? teacherData?.Subjects.split(" & ")
                        : []
                    }
                    onChange={(e) => {
                      let selectedSubjects = [...e.target.value];

                      // Limit to two selections, replace the first if a third is selected
                      if (selectedSubjects.length > 2) {
                        selectedSubjects = selectedSubjects.slice(-2); // Keep only the last two selected
                      }

                      // Sort the subjects alphabetically before combining
                      selectedSubjects.sort();

                      setTeacherData({
                        ...teacherData,
                        Subjects: selectedSubjects.join(" & "), // Combine with " & "
                      });
                    }}
                    renderValue={(selected) => selected.join(" & ")} // Display joined subjects
                  >
                    {subjectOptions.map((subject) => (
                      <MenuItem key={subject} value={subject}>
                        {subject}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel shrink={Boolean(teacherData?.CurrentSchool)}>
                    Current School
                  </InputLabel>
                  <OutlinedInput
                    label="Current School"
                    value={teacherData?.CurrentSchool || ""}
                    onChange={(e) =>
                      setTeacherData({
                        ...teacherData,
                        CurrentSchool: e.target.value,
                      })
                    }
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel shrink={Boolean(teacherData?.CurrentCounty)}>
                    Current County
                  </InputLabel>
                  <OutlinedInput
                    label="Current County"
                    value={teacherData?.CurrentCounty || ""}
                    onChange={(e) =>
                      setTeacherData({
                        ...teacherData,
                        CurrentCounty: e.target.value,
                      })
                    }
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel shrink={Boolean(teacherData?.CurrentSubCounty)}>
                    Current SubCounty
                  </InputLabel>
                  <OutlinedInput
                    label="Current SubCounty"
                    value={teacherData?.CurrentSubCounty || ""}
                    onChange={(e) =>
                      setTeacherData({
                        ...teacherData,
                        CurrentSubCounty: e.target.value,
                      })
                    }
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel shrink={Boolean(teacherData?.CurrentWard)}>
                    Current Ward
                  </InputLabel>
                  <OutlinedInput
                    label="Current Ward"
                    value={teacherData?.CurrentWard || ""}
                    onChange={(e) =>
                      setTeacherData({
                        ...teacherData,
                        CurrentWard: e.target.value,
                      })
                    }
                  />
                </FormControl>

                {teacherError && (
                  <Alert
                    severity={
                      teacherError.includes("success") ? "success" : "error"
                    }
                  >
                    {teacherError}
                  </Alert>
                )}
              </Stack>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                type="submit"
                disabled={isTeacherLoading}
              >
                {isTeacherLoading ? <CircularProgress size={24} /> : "Update"}
              </Button>
            </CardActions>
          </Card>
        </form>

        {/* Password Update Form */}
        <form onSubmit={handlePasswordSubmit}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <CardHeader subheader="Update password" title="Change Password" />
            <Divider />
            <CardContent>
              <Stack spacing={3} sx={{ maxWidth: "sm" }}>
                <FormControl fullWidth>
                  <InputLabel>Old Password</InputLabel>
                  <OutlinedInput
                    label="Old Password"
                    name="oldPassword"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>New Password</InputLabel>
                  <OutlinedInput
                    label="New Password"
                    name="newPassword"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Confirm New Password</InputLabel>
                  <OutlinedInput
                    label="Confirm New Password"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FormControl>
                {error && (
                  <Alert
                    severity={error.includes("success") ? "success" : "warning"}
                  >
                    {error}
                  </Alert>
                )}
              </Stack>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" disabled={isLoading}>
                {isLoading ? <CircularProgress size={24} /> : "Update"}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Stack>
    </Box>
  );
}
