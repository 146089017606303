import { Typography } from "@mui/material";
import Header from "../Components/Utils/header";
import "../Styles/nopage.scss";
import { Home } from "@mui/icons-material";

export default function NoPage(props) {
  return (
    <div
      style={{
        display: "grid",
        placeContent: "center",
        fontSize: "54px",
        height: "100vh",
      }}
      className="nopage"
    >
      <div>
        <Typography
          sx={{
            fontWeight: 900,
            textAlign: "center",
            color: "#252C36",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
          variant="h2"
          component="h2"
          gutterBottom
        >
          <span style={{ color: "#FA541C" }}>TSwapMate</span>
        </Typography>
        404 Not Found!
      </div>
    </div>
  );
}
